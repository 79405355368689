/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */
function copyTextToClipboard(text) {
  var textArea = document.createElement("textarea");

  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won't even render, not even a flash,
  // so some of these are just precautions. However in IE the element
  // is visible whilst the popup box asking the user for permission for
  // the web page to copy to the clipboard.
  //

  // Place in top-left corner of screen regardless of scroll position.
  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em';
  textArea.style.height = '2em';

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = 0;

  // Clean up any borders.
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = 'transparent';


  textArea.value = text;

  document.body.appendChild(textArea);

  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Copying text command was ' + msg);
  } catch (err) {
    console.log('Oops, unable to copy');
  }

  document.body.removeChild(textArea);
}

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                $('.widget_author_avatars .author-list .user').each(function() {
                    console.log($(this).find('a'));
                    $(this).find('.name').append($(this).find('.biography p'));
                });

                // JavaScript to be fired on all pages
                $('[data-toggle="tooltip"]').tooltip({
                    trigger:"manual"
                });
                $('.copy-to-clipboard').on("click",function(e){
                    e.preventDefault();
                    e.stopPropagation();
                    var $this = $(this);
                    copyTextToClipboard($(this).attr("href"));
                    $this.tooltip('show');
                    setTimeout(function(){
                        $this.tooltip('hide');
                    },3000);
                });
                // Facebook share
                $('.facebook-share-button').on("click",function(e){
                    e.preventDefault();
                    e.stopPropagation();
                    var lnk = $(this).data("href");
                    FB.ui({
                        method: 'share',
                        mobile_iframe: true,
                        href: lnk
                    }, function(response){});
                });

                // Twitter share
                $(".twitter-share-button").on("click",function(e){
                    e.preventDefault();
                    var href = $(this).attr('href');
                    window.open(href, "Twitter", "height=285,width=550,resizable=1");
                });

                // Xing Share
                $(".xing-share-button").on("click",function(e){
                    e.preventDefault();
                    var href = $(this).attr('href');
                    window.open(href, "Twitter", "height=500,width=550,resizable=1");
                });
                // mobile nav toggle
                $('#nav-icon').click(function(){
                    $(this).toggleClass('open');
                    $("#navigation").toggleClass("vis");
                });
                // Author list link inject
                $(".widget_author_avatars .author-list").append("<a class='all_authors' href='/autoren'>Alle Autoren anzeigen</a>");
                $(".button-mobile").click(function(e){
                    $(this).find("i").toggleClass('fa-times').toggleClass('fa-bars');
                    $(".sup-header #navigation").toggleClass('vis');
                });
                $('.js-load-more-posts').click(function(e) {
                    var button = $(e.currentTarget);
                    $.ajax({
                        type : "post",
                        url : appData.ajaxUrl,
                        data : {
                            action: "loadmore",
                            query: appData.loadMore.posts, // that's how we get params from wp_localize_script() function
                            page : appData.loadMore.currentPage
                        },
                        success: function(data) {
                            console.log(data);
                            if(data) {
                                var targetId = '#' + button.data('load-more-target');
                                $(targetId).append(data);
                                appData.loadMore.currentPage++;
                                button.blur();
                                if ( appData.loadMore.currentPage == appData.loadMore.maxPage ) {
                                    button.remove();
                                }
                            }
                        }
                    });
                });
            },

            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');

        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
